import { Button, DropDownProps, Dropdown, Menu, Space } from 'antd';
import { DownOutlined, BookOutlined } from '@ant-design/icons';

import { PropsWithChildren } from 'react';
import { TextEditorContextActions, useTextEditorContext } from './context';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import styled, { useTheme } from 'styled-components';

type DropdownItemType = Readonly<{
	/** The value for the dropdown item */
	value: string;
}> &
	ItemType;

type DropdownProps = Readonly<{
	/**
	 * Items to display in the dropdown
	 */
	items: DropdownItemType[];
}> &
	Omit<DropDownProps, 'overlay'>;

/**
 * Dropdown component for the TextEditor
 * @returns
 */
export function TextEditorDropdown(props: PropsWithChildren<DropdownProps>): JSX.Element {
	const { children, items, ...rest } = props;
	const { disabled, dispatchUpdate } = useTextEditorContext();
	const theme = useTheme();

	const menu = (
		<StyledMenu
			onClick={({ key }) => {
				const item = items.find((obj) => obj?.key === key);
				dispatchUpdate({
					type: TextEditorContextActions.setQuickResponse,
					payload: { text: item?.value ?? '', quickResponseId: key },
				});
			}}
			items={items}
		/>
	);

	return (
		<Dropdown trigger={['click']} overlay={menu} disabled={disabled} {...rest}>
			<Button type="text">
				<Space>
					<BookOutlined style={{ color: theme['@Gray/7'] }} />
					{children}
					<DownOutlined style={{ fontSize: '12px' }} />
				</Space>
			</Button>
		</Dropdown>
	);
}

const StyledMenu = styled(Menu)`
	max-width: 336px;
	max-height: 50vh;
	overflow: auto;
	.${(props) => props.theme['@ant-prefix']}-dropdown-menu-title-content {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

import React from 'react';
import { Col, Row, Popconfirm, Button } from 'antd';
import { ButtonSize } from 'antd/lib/button';

/**
 * Red button that displays a confirmation popup before commit
 *
 * @param {string} label button label
 * @param {string} confirmText text that is displayed in the confirmation popup
 * @param {callback} deleteAction action performed upon click and confirm
 * @param {ButtonSize} [size=middle] button size
 * @param {boolean} [disabled=false] whether button is disabled or not
 */

interface DeleteButtonProps {
	label: string;
	confirmText: string;
	deleteAction: () => void;
	size?: ButtonSize;
	disabled?: boolean;
}

const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
	const { label, confirmText, deleteAction, size = 'middle', disabled = false } = props;

	return (
		<Row justify="end">
			<Col>
				<Popconfirm
					title={confirmText}
					onConfirm={deleteAction}
					okText="Yes"
					cancelText="No"
					placement="rightBottom"
					disabled={disabled}
				>
					<Button danger type="primary" size={size} disabled={disabled}>
						{label}
					</Button>
				</Popconfirm>
			</Col>
		</Row>
	);
};

export default DeleteButton;

import { CampaignType } from '@copilot/data/responses/interface';
import { Menu } from 'antd';
import { CampaignDefaultName } from '../constant';
import { NameErrorCodes, validateNewName } from '..';
import { MenuIconType } from '@copilot/common/components/menus/sidebar';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { sorterByName } from '@copilot/common/utils/common/sort';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { Config } from '@copilot/common/config';
import Icon from '@ant-design/icons';
import { ReactComponent as PeopleSearchOutlined } from '@copilot/common/assets/icon/peopleSearch.svg';
import { ReactComponent as SproutOutlined } from '@copilot/common/assets/icon/sprout.svg';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { ICampaignType } from '../../../../core/data/graphql/_generated';
import {
	ALERT_CONNECTION_INTERESTED_RATE,
	ALERT_REPLY_RATE,
	SUCCESS_CONNECTION_INTERESTED_RATE,
	SUCCESS_REPLY_RATE,
} from '@copilot/common/constant/campaignConst';
import {
	ABOVE_AVERAGE_COLOR,
	AVERAGE_COLOR,
	BELOW_AVERAGE_COLOR,
} from '@copilot/common/pages/organizationDashboard/summaryV2/constants';

export enum CampaignStatsType {
	ConnectionRate = 'ConnectionRate',
	ReplyRate = 'ReplyRate',
	InterestedRate = 'InterestedRate',
}

/**
 * Get the category thresholds of the given metric type
 * @param statsType
 * @returns
 */
export function getCampaignStatsThresholds(statsType: CampaignStatsType) {
	let alertRate = 0,
		successRate = 0;
	switch (statsType) {
		case CampaignStatsType.ConnectionRate:
		case CampaignStatsType.InterestedRate:
			alertRate = ALERT_CONNECTION_INTERESTED_RATE;
			successRate = SUCCESS_CONNECTION_INTERESTED_RATE;
			break;
		case CampaignStatsType.ReplyRate:
			alertRate = ALERT_REPLY_RATE;
			successRate = SUCCESS_REPLY_RATE;
			break;
		default:
			break;
	}

	return { alertRate, successRate };
}

/**
 * return the associated color for campaign connection and interested rates
 * @param rate
 * @returns
 */
export const getCampaignStatsColor = (rate: number, statsType: CampaignStatsType) => {
	const { alertRate, successRate } = getCampaignStatsThresholds(statsType);
	if (rate < alertRate) return BELOW_AVERAGE_COLOR;
	else if (rate < successRate) return AVERAGE_COLOR;
	else return ABOVE_AVERAGE_COLOR;
};

/**
 * Gets the display name for the campaign type specified.
 * @param campaignType
 */
export const getCampaignTypeDisplayName = (campaignType: CampaignType) => {
	switch (campaignType) {
		case CampaignType.Nurture:
			return 'Nurture';
		case CampaignType.Prospecting:
			return 'Prospecting';
		default:
			return UtilityFunctions.assertUnreachable(campaignType);
	}
};

/**
 * Get Campaign Icon
 * @param {CampaignType} campaignType campaign type
 * @returns {ReactNode} campaign type icon
 */
export const getCampaignIcon = (campaignType: CampaignType) => {
	switch (campaignType) {
		case CampaignType.Prospecting:
			return <Icon component={PeopleSearchOutlined} />;
		case CampaignType.Nurture:
			return <Icon component={SproutOutlined} />;
		default:
			throw new Error(`CampaignType: ${campaignType} not handled`);
	}
};

/**
 * Get type of Campaign Icon
 * @param {CampaignType | ICampaignType} campaignType campaign type
 * @returns {MenuIconType} campaign icon type
 */
export const getCampaignTypeIcon = (campaignType: CampaignType | ICampaignType): MenuIconType => {
	switch (campaignType) {
		case ICampaignType.Prospecting:
		case CampaignType.Prospecting:
			return MenuIconType.PeopleSearchOutlined;
		case ICampaignType.Nurture:
		case CampaignType.Nurture:
			return MenuIconType.SproutOutlined;
		default:
			throw new Error(`CampaignType: ${campaignType} not handled`);
	}
};

/**
 * Determines if the campaign is enabled.
 * @param status
 */
export const isCampaignEnabled = (status: CampaignStatusEnum) =>
	status == CampaignStatusEnum.Enabled || status == CampaignStatusEnum.Running;

/**
 * Gets the campaign status display name.
 * @param status
 */
export const getCampaignStatusDisplayName = (status: CampaignStatusEnum) =>
	isCampaignEnabled(status) ? 'Active' : 'Disabled';

/**
 * Gets the simple campaign name validator which basically looks at all the campaign names to see if the name is already taken.
 * @param campaigns the list of campaign objects that has "name" property.
 */
export const getSimpleCampaignNameValidator =
	(campaigns: readonly { name: string }[]) => (nameToValidate: string) =>
		Promise.resolve(
			validateNewName(
				nameToValidate,
				campaigns.map((campaign) => campaign.name)
			)
		);

/**
 * Generate campaign creation options on campaign type
 * @param createCampaign function callback for creating campaign
 */
export const displayCampaignOptions = (createCampaign: (campaignType: CampaignType) => void) => (
	<Menu>
		<Menu.Item>
			<a onClick={() => createCampaign(CampaignType.Prospecting)}>
				{CampaignDefaultName.Prospecting}
			</a>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item>
			<a onClick={() => createCampaign(CampaignType.Nurture)}>
				{CampaignDefaultName.Nurture}
			</a>
		</Menu.Item>
	</Menu>
);

export const ErrorMessages: Record<NameErrorCodes, string> = {
	[NameErrorCodes.NoError]: '',
	[NameErrorCodes.NoName]: 'Please enter a campaign name',
	[NameErrorCodes.DuplicatedName]:
		'Name already used by another Campaign. Please enter a different name.',
	[NameErrorCodes.TooLong]: 'Exceed maximum number of characters',
};

/**
 * Gets the sorted list of campaigns.
 * campaigns are sorted by enabled/disabled first, and then name.
 * @param allCampaigns
 */
export const getSortedCampaigns = <T extends { name: string; status: CampaignStatusEnum }>(
	allCampaigns: readonly T[]
): T[] => {
	// array sort is in-place, so make a copy first.
	const activeCampaigns = allCampaigns.filter((campaign) => isCampaignEnabled(campaign.status));
	activeCampaigns.sort(sorterByName);

	const disabledCampaigns = allCampaigns.filter(
		(campaign) => !isCampaignEnabled(campaign.status)
	);
	disabledCampaigns.sort(sorterByName);

	return activeCampaigns.concat(...disabledCampaigns);
};

// ============= capability functions ==============
/**
 * Determines if the member can create a campaign in the individual app.
 * TODO: the "create" capability needs to be refactored when we merge individual & teams.
 * @param activeMember the member to check the capability.
 */
export const canCreateCampaignIndividual = (activeMember: IOrganizationMember): boolean =>
	!Config.isAgency && activeMember.isOnboarded;

/**
 * Determines if the member can create a campaign in the teams app.
 * TODO: the "create" capability needs to be refactored when we merge individual & teams.
 * @param activeMember the member to check the capability.
 */
export const canCreateCampaignTeam = (activeMember: IOrganizationMember): boolean =>
	activeMember.isOrgAdmin || activeMember.orgRoles.includes(OrgRoleTitles.Advanced);

/**
 * Determine if the member can see other team members
 * @param activeMember the member to check the capability.
 */
export const canSeeTeamMembers = (activeMember: IOrganizationMember): boolean =>
	activeMember.isOrgAdmin;

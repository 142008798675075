import { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Input, Form } from 'antd';
import { NameErrorCodes } from '@copilot/common/utils';
import { ErrorMessages } from '@copilot/common/utils/campaign';

export type NameValidator = (nameToValidate: string) => Promise<NameErrorCodes>;

interface INameEditorProps {
	description?: string;
	nameValidator: NameValidator;
	onContinue: (name: string) => void;
	isLoading: boolean;
}

/**
 * [Presentational] Modal Content for the Name Editor Step of Campaign Creation
 * @param {string} description description for the name editor modal
 * @param {NameValidator} nameValidator validates the name that is entered
 * @param {function} onContinue called when continue is clicked
 * @param {boolean} isLoading whether we want to show loading state
 */
const NameEditor: FC<INameEditorProps> = (props) => {
	const { description = 'Name your new campaign', nameValidator, isLoading } = props;
	const [name, setName] = useState<string>();
	const [nameError, setNameError] = useState<NameErrorCodes>();

	useEffect(() => {
		if (name === undefined) return;
		nameValidator.call(undefined, name).then((code) => setNameError(code));
	}, [name, nameValidator]);

	const onContinue = useCallback(() => {
		if (name) {
			props.onContinue(name);
		}
	}, [props.onContinue, name]);

	const validateStatus = useMemo(() => {
		const hasNameError =
			nameError === undefined ? undefined : nameError !== NameErrorCodes.NoError;
		return hasNameError ? 'error' : hasNameError === undefined ? undefined : 'success';
	}, [nameError]);

	return (
		<>
			<Row justify="center" gutter={[16, 8]}>
				<Col>
					<strong>{description}</strong>
				</Col>
			</Row>
			<br />
			<Row justify="center" gutter={[16, 16]}>
				<Col span={16}>
					<Form>
						<Form.Item
							help={nameError ? ErrorMessages[nameError] : undefined}
							validateStatus={validateStatus}
						>
							<Input onChange={(e) => setName(e.target.value)} />
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<br />
			<Row justify="center" gutter={[16, 16]}>
				<Col>
					<Button
						type="primary"
						block
						onClick={onContinue}
						disabled={nameError !== NameErrorCodes.NoError}
						loading={isLoading}
					>
						Continue
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default NameEditor;

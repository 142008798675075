import * as React from 'react';
import { Button, Popover } from 'antd';
import { CSVColumnDefinition } from '../../componentModels/filterTypeDefinition';
import Selector from '../../selector';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ButtonType } from 'antd/lib/button';

export interface IExportSelectorProps {
	columns: CSVColumnDefinition[];
	connectionIds?: string[];
	exportCSV?: (columns: CSVColumnDefinition[], connectionId?: string[]) => unknown;
	label: string;
	placement?: TooltipPlacement;
	type?: ButtonType;
	ghost?: boolean;
}

/**
 * Selector for Export CSV
 * @param columns a list of columns to include in CSV
 * @param connectionIds a list of connectionIds to export
 * @callback exportCSV called on export as CSV
 * @param label buttom label
 * @param placement placement of the columns popover
 * @param type button type
 * @param ghost whether we want to make button's background transparent or not
 */

const ExportSelector: React.FC<IExportSelectorProps> = (props) => {
	const {
		exportCSV,
		connectionIds,
		columns,
		label,
		placement = 'bottomRight',
		type = 'default',
		ghost = false,
	} = props;
	const selectorRef: React.RefObject<any> = React.useMemo(() => React.createRef(), []);
	const [visible, setVisible] = React.useState<boolean>(false);

	const toggleVisibility = (visibleParam?: boolean) => {
		const isVisible = visibleParam === undefined ? !visible : visibleParam;
		setVisible(isVisible);
		if (isVisible === false && selectorRef.current) {
			selectorRef.current.resetFields();
		}
	};

	const handleExportCSV = (csvColumns: CSVColumnDefinition[]) => {
		if (csvColumns && exportCSV) {
			exportCSV(csvColumns, connectionIds);
		}
		toggleVisibility(false);
	};

	const selector = (
		<Selector
			ref={selectorRef}
			columns={columns}
			filters={[]}
			updateExport={handleExportCSV}
			cancel={toggleVisibility}
		/>
	);

	return (
		<Popover
			content={selector}
			trigger="click"
			onVisibleChange={(isVisible) => setVisible(isVisible)}
			placement={placement}
			visible={visible}
			autoAdjustOverflow={false}
		>
			<Button onClick={() => toggleVisibility()} type={type} ghost={ghost}>
				{label}
			</Button>
		</Popover>
	);
};

export default ExportSelector;

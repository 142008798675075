import React, { useState, useEffect, useMemo, ReactNode } from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import BaseDrawer from '../base';
import styled from 'styled-components';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Row, Col, Button } from 'antd';
import type { OrganizationMember } from '@copilot/common/store/models/redux';
import type { Ref } from 'redux-orm';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useFetch } from '@copilot/common/hooks/common';
import { PushMethods } from '@copilot/data/responses/interface';
import { NotificationManager } from '@copilot/data';
import { PushNotificationSelectors } from '@copilot/common/store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { PushNotificationActions } from '@copilot/common/store/actions';
import drawerManager from '@copilot/common/utils/drawerManager';

interface OwnProps extends DrawerProps {
	notificationCode: string;
	notificationLabel: string;
	eligibleMembers: readonly Ref<OrganizationMember>[];
	subscribedMemberIds: string[];
	closeAlert: boolean;
}

export type NotificationDrawerProps = OwnProps;

const StyledRow = styled(Row)`
	margin: 5px 0;
	padding: 5px;

	&:hover {
		background: #fafafa;
	}
`;

const StaticBaseDrawer = styled(BaseDrawer)`
	&& > .${(props) => props.theme['@ant-prefix']}-drawer-content-wrapper {
		transform: translate(0px, 0px);
		overflow: hidden;
	}
`; // Make base drawer be the containing block for any position fixed elements within the drawer

interface MemberRowProps extends React.ComponentProps<typeof Row> {
	name: string;
	icon: ReactNode;
	onClick: (event: unknown) => void;
}

const MemberRow = (props: MemberRowProps) => {
	const { name, icon, onClick, ...rest } = props;
	return (
		<StyledRow align="middle" {...rest}>
			<Col span={20}>{name}</Col>
			<Col span={4}>
				<Button shape="circle" type="primary" onClick={onClick}>
					{icon}
				</Button>
			</Col>
		</StyledRow>
	);
};

const NotificationDrawer: React.FC<NotificationDrawerProps> = (props: NotificationDrawerProps) => {
	const {
		notificationCode,
		notificationLabel,
		eligibleMembers,
		subscribedMemberIds,
		closeAlert: _,
		...rest
	} = props;

	const dispatch = useDispatch();
	const [members, setMembers] = useState<TypedObject<{ name: string; subscribed: boolean }>>({});
	const [, postUpdate] = useFetch(NotificationManager.updateNotificationSubscriptions);
	const subscriptionInfo = useSelector((state) =>
		PushNotificationSelectors.getNotificationByCode(state, notificationCode)
	);
	useEffect(() => {
		const allMembers = eligibleMembers.reduce<
			TypedObject<{ name: string; subscribed: boolean }>
		>((acc, item) => {
			acc[item.id] = { name: `${item.firstName} ${item.lastName}`, subscribed: false };
			return acc;
		}, {});
		subscribedMemberIds.forEach((id) => {
			if (allMembers[id]) allMembers[id].subscribed = true;
		});
		setMembers(allMembers);
	}, [eligibleMembers, subscribedMemberIds]);

	const updateSubscription = () => {
		const subscribed: string[] = [];
		const notSubscribed: string[] = [];
		const subscriptions = { ...(subscriptionInfo?.subscriptions ?? {}) };
		Object.keys(members).forEach((id) => {
			members[id].subscribed ? subscribed.push(id) : notSubscribed.push(id);
			subscriptions[id] = members[id].subscribed ? PushMethods.Email : PushMethods.None;
		});
		const updates = [];
		if (subscribed.length) {
			updates.push(
				postUpdate(notificationCode, {
					ownerIds: subscribed,
					pushMethods: [PushMethods.Email],
				})
			);
		}
		if (notSubscribed.length) {
			updates.push(
				postUpdate(notificationCode, { ownerIds: notSubscribed, pushMethods: [] })
			);
		}
		Promise.all(updates)
			.then(() => {
				notificationManager.showSuccessNotification({
					message: 'Saved',
					key: 'notification',
				});
				dispatch(
					PushNotificationActions.updateOne({
						id: subscriptionInfo?.id ?? '',
						subscriptions,
					})
				);
				drawerManager.closeDrawer();
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Error',
					description: 'Updated failed. Please try again.',
					key: 'notification',
				});
			});
	};

	const subscriptionUpdate = (id: string) => {
		setMembers({
			...members,
			[id]: { ...members[id], subscribed: !members[id].subscribed },
		});
	};

	const content = useMemo(() => {
		const subscribed: ReactNode[] = [];
		const notsubscribed: ReactNode[] = [];
		Object.keys(members).forEach((id) => {
			members[id].subscribed
				? subscribed.push(
						<MemberRow
							key={id}
							name={members[id].name}
							onClick={() => subscriptionUpdate(id)}
							icon={<MinusOutlined />}
						/>
				  )
				: notsubscribed.push(
						<MemberRow
							key={id}
							name={members[id].name}
							onClick={() => subscriptionUpdate(id)}
							icon={<PlusOutlined />}
						/>
				  );
		});
		return (
			<>
				<Card title="Member to get notification">{subscribed}</Card>
				<br />
				<Card title="Member to not get notification">{notsubscribed}</Card>
				<br />
			</>
		);
	}, [members]);

	const footer = React.useMemo(
		() => (
			<Row justify="space-between">
				<Col>
					<Button onClick={drawerManager.closeDrawer}>Discard</Button>
				</Col>
				<Col>
					<Button type="primary" onClick={updateSubscription}>
						Save
					</Button>
				</Col>
			</Row>
		),
		[updateSubscription]
	);

	return (
		<StaticBaseDrawer {...rest} footer={footer}>
			<div style={{ minHeight: '100vh' }}>
				<h2>{notificationLabel}</h2>
				{content}
			</div>
		</StaticBaseDrawer>
	);
};

export default NotificationDrawer;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Divider, Empty, Modal, Popconfirm, Space, Switch, Typography } from 'antd';
import SearchUrlSection from '@copilot/common/components/editors/searchUrl/searchUrl';
import { SearchUrlType } from '@copilot/common/utils/constant';
import {
	useCampaignMemberLinkedInfoFetch,
	useUpdateLinkedInInfoFetch,
} from '@copilot/common/hooks/requests/campaignMember';
import modalManager from '@copilot/common/utils/modalManager';
import { CampaignMemberManager, LinkedInManager } from '@copilot/data';
import { useDebounce, useFetch, useFetchV2 } from '@copilot/common/hooks/common';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useSelector } from 'react-redux';
import { LinkedinSelectors } from '@copilot/common/store/selectors/linkedin';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { isSearchUrlValid, throwError } from '@copilot/common/utils';
import { Config } from '@copilot/common/config';
import { addLink } from '@copilot/common/utils/common';
import { LoggedOutLIWarning } from '../../const';
import { Features, MultipleInvite } from '@copilot/data/responses/interface';
import InstructionSteps from '@copilot/common/components/instructionSteps';
import TeamMemberDropdownWithTitle from '@copilot/common/pages/campaignDashboard/teamMemberDropDown';
import { SearchInputPlaceholder } from '@copilot/common/components/search/input';
import {
	CampaignType,
	ICampaignDashboardSearchListBaseProps,
	INITIAL_MULTIPLE_INVITE,
	MANAGE_SEARCH_URL_STEPS_TITLE,
	ManageSearchUrlInstructionSteps,
	OPEN_PROFILE_TAB_KEY,
	PROSPECT_TAB_KEY,
	SearchListTableTabKeys,
} from '../const';
import { CampaignMemberModel, getName } from '@copilot/common/utils/campaignMember/models';
import { usePermission, validateIsEditable } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { useSearchListTracking } from '../tracking';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { useSearchListCount } from '@copilot/common/pages/campaignDashboard/searchList/useSearchListCount';
import Title from '@copilot/common/typography/title';
import { OpenInMailInfoIcon } from '@copilot/common/pages/campaignDashboard/searchList/openInMailInfoIcon';
import { isNil, isObject, isUndefined } from 'lodash';
import { OpenProfileSearchListOverview } from '@copilot/common/pages/campaignDashboard/searchList/openProfileSearchListOverview';
import { SearchListOverview } from '@copilot/common/pages/campaignDashboard/searchList/searchListOverview';
import {
	getInMailAllocationModalConfig,
	getInMailMissingSequenceWarningModalConfig,
	getInMailPauseSequenceWarningModalConfig,
} from './util';
import { useSearchListTables } from '@copilot/common/pages/campaignDashboard/searchList/useSearchListTable';
import { useDailyOpenInMailLimit } from '@copilot/common/pages/campaignDashboard/searchList/campaignDashboardSearchList/useDailyOpenInMailLimitHook';
import {
	useCampaignsByUserLazyQuery,
	useStopAutomationMutation,
} from '@copilot/data/graphql/_generated';
import useCampaignMemberSelection from './useCampaignMemberSelection';
import { LoadingPane } from '@copilot/common/components/loadingPane/loadingPane';
import styles from '../../searchListCommon/searchListCommon.module.less';
import { HookAPI } from 'antd/es/modal/useModal';
import { SEARCH_STATUSES } from '@copilot/common/components/editors/searchUrl/update/consts';
import useSearchTaskMonitor from '../../useSearchListTaskMonitor';
import { getCommonSearchMonitorConfig } from '../../searchListCommon/helperFunctions';
import { useProspectingSearchListV2 } from '@copilot/common/pages/campaignDashboard/searchList/useProspectingSearchListV2';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchListTableData } from '@copilot/common/pages/campaignDashboard/searchList/table';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { SearchListTableWithOpenProfiles } from '@copilot/common/pages/campaignDashboard/searchList/searchListTableWithOpenProfiles';

type DailyInmMailProps = {
	dailyLimitAvailable: number;
	dailyLimit: number;
	isEnabled: boolean;
};

/**
 * Tab container holding search list components
 */
export default function CampaignDashboardSearchList(props: ICampaignDashboardSearchListBaseProps) {
	const isBlocklistRefactorEnabled = useFeatureToggle(Features.DoNotProspectRefactorFeature);
	const isStopAutomationRefactorEnabled = useFeatureToggle(
		Features.StopAutomationRefactorFeature
	);
	const isSearchFeatureOn = useFeatureToggle(Features.RunSearchListFeature);
	const {
		campaignId,
		isTeamUser,
		isMissingInMailSequence,
		onSetupInMailSequence,
		onSearchListStatusChange,
	} = props;
	const {
		campaignMemberOptions,
		campaignMember,
		onCampaignMemberSelect,
		isLoadingCampaignMembers,
	} = useCampaignMemberSelection(props);

	const [modal, contextHolder] = Modal.useModal();

	const updateCampaignSearchListTracking = useSearchListTracking(
		'Campaign Dashboard Search List',
		null
	);
	const [isSecondPopconfirmVisible, setIsSecondPopconfirmVisible] = useState<boolean>(false);

	const [searchListTableTab, setSearchListTableTab] =
		useState<SearchListTableTabKeys>(PROSPECT_TAB_KEY);

	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const disabledWarning = useMemo(
		() =>
			isAdmin
				? LoggedOutLIWarning(activeMember?.isLiSessionInRetryState)
				: addLink(
						LoggedOutLIWarning(activeMember?.isLiSessionInRetryState),
						campaignMember?.orgMemberId === activeMember?.id
							? '/settings/linkedin'
							: `/?tab=teammembers&teamMembersRedirectMemberId=${campaignMember?.orgMemberId}`
				  ),
		[isAdmin, campaignMember, activeMember]
	);

	const omid = useMemo(
		() => (isAdmin && campaignMember?.orgMemberId ? campaignMember.orgMemberId : undefined),
		[isAdmin, campaignMember?.orgMemberId]
	);

	const [{ counts }, refetchCount] = useSearchListCount(campaignId, campaignMember?.id, omid);
	const [
		setSearchTerm,
		,
		handleTableChange,
		pagination,
		fetchSearchListResults,
		searchListResults,
	] = useSearchListTables(campaignId, campaignMember, omid, searchListTableTab);

	const {
		prospectResults,
		openProfileResults,
		prospectTotalCounts,
		openProfileTotalCounts,
		isLoadingProspects,
		isLoadingOpenProfiles,
		prospectsPageSize,
		openProfilesPageSize,
		initSearchLists: fetchSearchListResultsV2,
		refetchSearchLists: refetchSearchListResultsV2,
		refetchTotalCounts: refetchTotalCountsV2,
		onUpdateSearchTerm,
		onUpdatePagination,
	} = useProspectingSearchListV2();

	const [multipleInvite, setMultipleInvite] = useState<MultipleInvite>(INITIAL_MULTIPLE_INVITE);
	const campaignMemberName = useMemo(
		() => (campaignMember ? getName(campaignMember) : undefined),
		[campaignMember]
	);

	const [searchListPermission] = usePermission(PermissionName.SearchList);
	const [ownSearchListPermission] = usePermission(PermissionName.OwnSearchList);
	const isAdvancedTeamUser =
		isTeamUser && activeMember?.orgRoles.includes(OrgRoleTitles.Advanced);
	const canEditSearchList = useMemo(
		() => validateIsEditable(searchListPermission | ownSearchListPermission),
		[searchListPermission, ownSearchListPermission]
	);

	const [, fetchLinkedInInfo] = useCampaignMemberLinkedInfoFetch();
	const [updateLinkedInFetch, updateLinkedInInfo] = useUpdateLinkedInInfoFetch();

	const linkedInMeta = useSelector((state) =>
		LinkedinSelectors.getOrgMemberLinkedInMeta(state, campaignMember?.orgMemberId)
	);
	const [campaignInitializer, initializeCampaign] = useFetch(
		LinkedInManager.initializeLinkedInCampaign
	);
	const [, rejectConnection] = useFetch(LinkedInManager.rejectConnection);

	const [stopAutomation] = useStopAutomationMutation();

	/**
	 * Marks a connection as rejected
	 * @param id
	 * @param memberId
	 * @param connectionId
	 */
	async function markConnectionAsRejected(id: string, memberId: string, connectionId: string) {
		if (isStopAutomationRefactorEnabled) {
			const { errors } = await stopAutomation({
				variables: {
					campaignConnectionId: connectionId,
				},
			});

			if (!isNil(errors)) throw new Error('Failed to stop automation');
		} else {
			rejectConnection(id, memberId, connectionId);
		}
	}

	// Adds a check to see if the search list is running
	const { searchStatus, startMonitoringTaskStatus, hasSearchListStatusBeenChecked } =
		useSearchTaskMonitor({
			orgMemberId: campaignMember?.orgMemberId,
			campaignId,
			...getCommonSearchMonitorConfig(
				isBlocklistRefactorEnabled ? refetchSearchListResultsV2 : fetchSearchListResults,
				onSearchListStatusChange
			),
		});

	useEffect(() => {
		if (campaignMember?.id) {
			fetchLinkedInInfo(campaignId, campaignMember.id);
			setMultipleInvite(INITIAL_MULTIPLE_INVITE);
		}

		// Load search list using search list v2 if blocklist refactor is enabled
		if (isBlocklistRefactorEnabled) {
			fetchSearchListResultsV2(campaignMember);
		}
	}, [fetchLinkedInInfo, campaignId, campaignMember?.id]);

	/**
	 * Callback for opening the modal for search building help
	 */
	function handleSearchVideoLinkClick() {
		modalManager.openVideoModal({
			videoType: 'SearchVideo',
			width: 800,
			centered: true,
			hideFooter: true,
		});
	}

	/**
	 * Callback for saving a search url and then performing a search
	 * @param url The url we want to update to
	 * @param initializeSearchList Whether we want to remove the old prospects or not
	 */
	async function handleSaveAndSearch(url: string, initializeSearchList: boolean) {
		if (!campaignId || !campaignMember?.id)
			throw new Error('Campaign or campaign id not found.');

		const isSearchUrlIdentical =
			isTeamUser || isAdmin ? false : url === campaignMember.searchUrl;
		// Prevent individual users from running the same search multiple times
		if (isSearchUrlIdentical) {
			return notificationManager.showWarnNotification({
				message: 'Search Error',
				description:
					'Your new search is identical to the old search. Search has not been performed.',
				duration: 5,
			});
		}
		if (!isAdmin && isTeamUser) {
			updateCampaignSearchListTracking({
				buttonClicked: 'Search List Tab Search - All Team Users',
			});
			if (isAdvancedTeamUser) {
				updateCampaignSearchListTracking({
					buttonClicked: 'Search List Tab Search - Advanced Team User',
				});
			}
		}
		await updateLinkedInInfo(campaignId, campaignMember.id, { searchUrl: url, multipleInvite });
		try {
			await initializeCampaign(campaignId, campaignMember.id, initializeSearchList);
			if (initializeSearchList) {
				isBlocklistRefactorEnabled
					? refetchSearchListResultsV2()
					: fetchSearchListResults();
			}
			startMonitoringTaskStatus();
			onSearchListStatusChange?.(SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN);
			notificationManager.showSuccessNotification({
				message: 'Search Saved',
				description: 'Your search url has been saved and a search has been started.',
			});
		} catch (err: any) {
			const isConflict = typeof err === 'object' && err != null && err.status === 409;
			notificationManager.showErrorNotification({
				message: 'Search Error',
				description: isConflict
					? 'You are already running a search in one of your campaigns. Please wait until your search finishes.'
					: 'Unable to perform search.',
				duration: 8,
			});
		}
		return undefined;
	}

	function onSecondPopconfirmVisibleChange(visible: boolean) {
		if (!visible) {
			setIsSecondPopconfirmVisible(false);
		}
	}

	const isInitialSearch = useMemo(
		() => !campaignMember?.lastSearchUrlUpdate && !campaignMember?.searchUrl,
		[campaignMember?.lastSearchUrlUpdate, campaignMember?.searchUrl]
	);

	const [isUpdatingDailyInMailLimit, setIsUpdatingDailyInMailLimit] = useState<boolean>(false);
	const [hasUpdatingDailyInMailLimitFailed, setHasUpdatingDailyInMailLimitFailed] =
		useState<boolean>(false);

	const { data: openInMailLimit, loading: isOpenInMailLimitUpdating } = useDailyOpenInMailLimit(
		activeMember?.organizationId,
		campaignMember?.orgMemberId
	);

	const [openInMailState, setOpenInMailState] = useState({
		dailyLimitAvailable: 0,
		dailyLimit: 0,
		isEnabled: false,
	});

	function onUpdateOpenInMailState(updatedState: Partial<DailyInmMailProps>): void {
		setOpenInMailState({
			...openInMailState,
			...updatedState,
		});
	}

	// Storing the dailyLimit and dailyLimitAvailable as a local state because the value of campaignMember?.dailyOpenInMailLimit
	// is based on the elastic search view. The value might be stale when the view is updating.
	useEffect(() => {
		const limit = campaignMember?.dailyOpenInMailLimit;
		if (isNil(limit)) return;

		const openInMailAllocatedInOtherCampaigns = (openInMailLimit?.allocated ?? 0) - limit;

		const openInMailAvailableToAllocate =
			(openInMailLimit?.limit ?? 0) - openInMailAllocatedInOtherCampaigns;
		onUpdateOpenInMailState({
			dailyLimitAvailable: openInMailAvailableToAllocate,
			dailyLimit: limit,
		});
	}, [campaignMember?.id, openInMailLimit?.allocated, openInMailLimit?.limit]);

	const [, updateLinkedInInformation] = useFetchV2(
		CampaignMemberManager.updateCampaignMemberLinkedInInformation
	);
	async function updateDailyOpenInMailLimit(limit: number): Promise<void> {
		const campaignMemberId = campaignMember?.campaignMemberId;
		if (isUndefined(campaignMemberId))
			throwError('Unable to update due to missing campaign member id');
		if (limit > openInMailState.dailyLimitAvailable)
			throwError(`Unable to exceed ${openInMailState.dailyLimitAvailable} per day`);
		try {
			setHasUpdatingDailyInMailLimitFailed(false);
			setIsUpdatingDailyInMailLimit(true);
			await updateLinkedInInformation(campaignId, campaignMemberId, {
				dailyOpenInMailLimit: limit,
				openInMailEnabled: limit == 0 ? false : undefined,
			});
			if (limit == 0) {
				onUpdateOpenInMailState({ isEnabled: false });
			}
		} catch {
			setHasUpdatingDailyInMailLimitFailed(true);
		} finally {
			setIsUpdatingDailyInMailLimit(false);
		}
	}

	const updateDailyOpenInMailLimitDebounce = useDebounce(updateDailyOpenInMailLimit, 1000);
	const numContactCollected =
		isNil(counts.openProfile) || isNil(counts.prospect)
			? undefined
			: counts.openProfile + counts.prospect;

	/**
	 * Button and confirmation popup for performing a search
	 * @param searchUrl the search url we will search for
	 */
	const saveAndSearchButton = useCallback(
		(searchUrl: string) => {
			const numPeople = numContactCollected ?? 0;
			const isSearchDisabled =
				!isSearchFeatureOn ||
				!linkedInMeta?.isLoggedIn ||
				!isSearchUrlValid(searchUrl) ||
				searchStatus == SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN;
			const performSearchText = isSearchFeatureOn ? 'Run Search' : 'Under Maintenance';
			const isSaving = campaignInitializer.isFetching || updateLinkedInFetch.isFetching;
			const isInitialSearchAndEmpty = !numPeople && isInitialSearch;
			if (!isInitialSearchAndEmpty && numPeople > 0) {
				return (
					<Popconfirm
						title={`Are you sure? The ${numPeople} prospects will be removed from your Prospects List.`}
						open={isSecondPopconfirmVisible}
						onOpenChange={onSecondPopconfirmVisibleChange}
						onConfirm={() => {
							handleSaveAndSearch(searchUrl, true);
						}}
						okText="Yes"
						cancelText="Cancel"
					>
						<Popconfirm
							title={`Would you still like to invite the ${numPeople} prospects from your previous search list?`}
							onConfirm={() => {
								handleSaveAndSearch(searchUrl, false);
							}}
							onCancel={() => setIsSecondPopconfirmVisible(true)}
							okText="Yes"
							cancelText="No"
							disabled={isSearchDisabled}
						>
							<Button type="primary" disabled={isSearchDisabled} loading={isSaving}>
								{isSaving ? 'Saving' : performSearchText}
							</Button>
						</Popconfirm>
					</Popconfirm>
				);
			} else {
				return (
					<Button
						type="primary"
						disabled={isSearchDisabled}
						onClick={() => {
							handleSaveAndSearch(searchUrl, true);
						}}
						loading={isSaving}
					>
						{isSaving ? 'Saving' : performSearchText}
					</Button>
				);
			}
		},
		[
			handleSaveAndSearch,
			onSecondPopconfirmVisibleChange,
			isSecondPopconfirmVisible,
			isInitialSearch,
			numContactCollected,
			linkedInMeta?.isLoggedIn,
			campaignInitializer.isFetching,
			searchStatus,
		]
	);

	/**
	 * Callback for removing a prospect
	 * @param connectionId The id of the connection we want to remove\
	 * @param campaignMemberId The id of the campaign member
	 */
	async function removeProspect(connectionId: string, campaignMemberId: string) {
		if (campaignId) {
			try {
				await markConnectionAsRejected(campaignId, campaignMemberId, connectionId);
				isBlocklistRefactorEnabled ? await refetchTotalCountsV2() : await refetchCount();
				notificationManager.showSuccessNotification({
					message: 'Prospect Removed',
					description: 'Prospect removed from the sequence.',
				});
			} catch (err) {
				notificationManager.showErrorNotification({
					message: 'Prospect Removal Failed',
					description:
						'Unable to remove this prospect from the sequence. Please try again later.',
				});
			}
			isBlocklistRefactorEnabled ? refetchSearchListResultsV2() : fetchSearchListResults();
		}
	}

	const searchUrlTitle = useMemo(() => {
		if (isTeamUser && campaignMember?.firstName) {
			return `${campaignMember.firstName}'s search URL`;
		} else return isInitialSearch ? 'Input Sales Navigator URL' : 'Search List';
	}, [isTeamUser, campaignMember?.firstName, isInitialSearch]);

	const showSelectMemberInstructions = !campaignMember && campaignMemberOptions;
	useEffect(() => {
		if (isNil(campaignMember)) return;
		onUpdateOpenInMailState({ isEnabled: campaignMember.openInMailEnabled });
	}, [campaignMember?.openInMailEnabled]);

	const canUpdateOpenInMailSetting =
		activeMember?.isOrgAdmin === true ||
		activeMember?.orgRoles.includes(OrgRoleTitles.Advanced);

	const [getAllocations, { loading: isLoadingAllocations }] = useCampaignsByUserLazyQuery();

	/**
	 * Callback to display user's InMail's allocation
	 */
	function onShowInMailAllocations(): void {
		getAllocations({ variables: { input: { orgMemberId: campaignMember?.orgMemberId } } })
			.then(({ data }) => {
				const campaigns = data?.campaigns;
				if (isNil(campaigns)) {
					notificationManager.showErrorNotification({
						message: "Unable to get user's allocations. Please try again.",
					});
					return;
				}
				const allocations: { campaignName: string; allocated: number }[] = campaigns
					.filter((campaign) => {
						return campaign.type === CampaignType.prospecting;
					})
					.map((campaign) => {
						const member = campaign?.members?.find(
							({ orgMemberId }) => orgMemberId === campaignMember?.orgMemberId
						);
						return {
							campaignName:
								campaign.name ?? throwError('Unable to get the campaign name'),
							allocated: member?.dailyOpenInMailAllocated ?? 0,
						};
					});
				modal.info(getInMailAllocationModalConfig(allocations));
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: "Unable to get user's allocations. Please try again.",
				});
			});
	}

	/**
	 * Callback to update table pagination (search list v2)
	 * @param paginationUpdate
	 * @param filtersUpdate
	 * @param sorterUpdate
	 */
	function onUpdateTable(
		paginationUpdate: TablePaginationConfig,
		filtersUpdate: Record<string, FilterValue | null>,
		sorterUpdate: SorterResult<SearchListTableData> | SorterResult<SearchListTableData>[]
	) {
		if (isBlocklistRefactorEnabled) {
			onUpdatePagination(
				searchListTableTab,
				paginationUpdate.pageSize,
				paginationUpdate.current
			);
		} else {
			handleTableChange(paginationUpdate, filtersUpdate, sorterUpdate);
		}
	}

	/**
	 * Callback to update search term (search list v2)
	 * @param searchTerm
	 */
	function onSearch(searchTerm: string) {
		if (isBlocklistRefactorEnabled) {
			onUpdateSearchTerm(searchTerm);
		} else {
			setSearchTerm(searchTerm);
		}
	}

	function getOpenProfileSearchListCount() {
		if (isBlocklistRefactorEnabled) {
			return openProfileResults.count ?? 0;
		} else {
			return isObject(pagination) ? pagination.total ?? 0 : 0;
		}
	}

	function getProspectSearchListCount() {
		if (isBlocklistRefactorEnabled) {
			return prospectResults.count ?? 0;
		} else {
			return isObject(pagination) ? pagination.total ?? 0 : 0;
		}
	}

	function getDataSource() {
		if (isBlocklistRefactorEnabled) {
			return searchListTableTab === OPEN_PROFILE_TAB_KEY
				? openProfileResults.content
				: prospectResults.content;
		} else {
			return searchListResults.data?.results;
		}
	}

	function getPagination() {
		if (isBlocklistRefactorEnabled) {
			return searchListTableTab === OPEN_PROFILE_TAB_KEY
				? {
						total: openProfileResults.count,
						current: openProfileResults.pages.current,
						pageSize: openProfilesPageSize,
				  }
				: {
						total: prospectResults.count,
						current: prospectResults.pages.current,
						pageSize: prospectsPageSize,
				  };
		} else {
			return pagination;
		}
	}

	function getIsLoading() {
		if (isBlocklistRefactorEnabled) {
			return searchListTableTab === OPEN_PROFILE_TAB_KEY
				? isLoadingOpenProfiles
				: isLoadingProspects;
		} else {
			return searchListResults.isFetching;
		}
	}

	function getSearchListTotalCounts() {
		if (isBlocklistRefactorEnabled) {
			return { openProfile: openProfileTotalCounts, prospect: prospectTotalCounts };
		} else {
			return counts ?? {};
		}
	}

	if (isLoadingCampaignMembers) {
		return <LoadingPane isLoading />;
	}

	return (
		<>
			{canEditSearchList && (
				<Card className={styles.searchUrlModule}>
					{!isNil(campaignMemberOptions) && (
						<TeamMemberDropdownWithTitle
							selectedMemberName={campaignMemberName}
							options={campaignMemberOptions}
							onSelect={onCampaignMemberSelect}
						/>
					)}

					{!showSelectMemberInstructions && (
						<>
							<div className={styles.searchListTitle}>{searchUrlTitle}</div>
							<SearchUrlSection
								searchType={SearchUrlType.Search}
								numPeople={numContactCollected}
								updateDate={campaignMember?.lastSearchUrlUpdate}
								url={campaignMember?.searchUrl}
								searchStatus={searchStatus}
								isInitialSearch={isInitialSearch}
								isLoading={!hasSearchListStatusBeenChecked}
								showVideoLink={!Config.isAgency}
								saveAndSearchButton={saveAndSearchButton}
								onVideoLinkClicked={handleSearchVideoLinkClick}
								saveDisableWarning={
									!linkedInMeta?.isLoggedIn &&
									isSearchFeatureOn &&
									disabledWarning
								}
								multipleInvite={isTeamUser ? multipleInvite : undefined}
								updateMultipleInvite={isTeamUser ? setMultipleInvite : undefined}
							/>
						</>
					)}
				</Card>
			)}
			{showSelectMemberInstructions ? (
				<div className={styles.instructionWrapper}>
					<InstructionSteps
						title={MANAGE_SEARCH_URL_STEPS_TITLE}
						steps={ManageSearchUrlInstructionSteps}
						stepWidth={215}
						showShadow
					/>
				</div>
			) : (
				<div className={styles.searchListTableWrapper}>
					<SearchListTableWithOpenProfiles
						tableHeader={
							<CampaignSearchListTitle
								campaignId={campaignId}
								isMissingInMailSequence={isMissingInMailSequence}
								onUpdateOpenInMailState={onUpdateOpenInMailState}
								isOpenInMailUpdating={isOpenInMailLimitUpdating}
								modal={modal}
								openInMailState={openInMailState}
								canUpdateOpenInMailSetting={canUpdateOpenInMailSetting ?? false}
								onSetupInMailSequence={onSetupInMailSequence}
								campaignMember={campaignMember}
							/>
						}
						tableOverview={
							searchListTableTab === OPEN_PROFILE_TAB_KEY ? (
								<OpenProfileSearchListOverview
									count={getOpenProfileSearchListCount()}
									allocationLimit={openInMailLimit?.limit ?? 0}
									numAvailableToAllocate={openInMailState.dailyLimitAvailable}
									allocated={openInMailState.dailyLimit}
									onUpdateDailyLimit={(limit: number) => {
										onUpdateOpenInMailState({ dailyLimit: limit });
										updateDailyOpenInMailLimitDebounce(limit);
									}}
									isLimitUpdating={
										isUpdatingDailyInMailLimit || isOpenInMailLimitUpdating
									}
									isInMailEnabled={openInMailState.isEnabled}
									hasLimitUpdateFailed={hasUpdatingDailyInMailLimitFailed}
									canEdit={canUpdateOpenInMailSetting}
									isLoadingAllocations={isLoadingAllocations}
									onShowAllocations={onShowInMailAllocations}
								/>
							) : (
								<SearchListOverview count={getProspectSearchListCount()} />
							)
						}
						dataSource={getDataSource()}
						pagination={getPagination()}
						loading={getIsLoading()}
						onStopAutomation={removeProspect}
						onChange={onUpdateTable}
						onSearch={isBlocklistRefactorEnabled ? onSearch : setSearchTerm}
						searchPlaceholder={SearchInputPlaceholder.Name}
						activeTabKey={searchListTableTab}
						setActiveTabKey={setSearchListTableTab}
						counts={getSearchListTotalCounts()}
						locale={{
							emptyText: (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										searchListTableTab === OPEN_PROFILE_TAB_KEY
											? 'No Open LinkedIn Profiles at this time for InMail outreach'
											: 'No data'
									}
								/>
							),
						}}
					/>
				</div>
			)}

			{contextHolder}
		</>
	);
}

type CampaignSearchListTitleProps = {
	campaignMember?: CampaignMemberModel;
	campaignId: string;
	isMissingInMailSequence: boolean;
	onSetupInMailSequence?: () => void;
	isOpenInMailUpdating: boolean;
	canUpdateOpenInMailSetting: boolean;
	openInMailState: DailyInmMailProps;
	onUpdateOpenInMailState: (updatedState: Partial<DailyInmMailProps>) => void;
	modal: HookAPI;
};

function CampaignSearchListTitle({
	campaignMember,
	campaignId,
	isMissingInMailSequence,
	onSetupInMailSequence,
	canUpdateOpenInMailSetting,
	openInMailState,
	onUpdateOpenInMailState,
	modal,
}: CampaignSearchListTitleProps) {
	const [{ isFetching: isOpenInMailUpdating }, updateIsOpenInMail] = useFetch(
		CampaignMemberManager.updateCampaignMemberLinkedInInformation
	);

	return (
		<Space>
			<Title level={4}>
				{isUndefined(campaignMember?.firstName) ? null : `${campaignMember?.firstName}'s`}{' '}
				Search List
			</Title>
			{canUpdateOpenInMailSetting && (
				<>
					<Divider type={'vertical'} />
					<Typography.Text>Send InMail to Open Profiles</Typography.Text>
					<OpenInMailInfoIcon />
					<Switch
						onChange={(state) => {
							if (isNil(campaignMember)) return;
							if (state && isMissingInMailSequence) {
								// Open "You haven't set up an InMail messaging sequence yet" Modal
								modal.confirm(
									getInMailMissingSequenceWarningModalConfig(() =>
										onSetupInMailSequence?.()
									)
								);

								return;
							} else if (state) {
								updateIsOpenInMail(campaignId, campaignMember.id, {
									openInMailEnabled: true,
								}).then(() =>
									onUpdateOpenInMailState({
										isEnabled: true,
									})
								);
							} else {
								// open "Pause InMail Sequence" Modal
								modal.confirm(
									getInMailPauseSequenceWarningModalConfig(() => {
										updateIsOpenInMail(campaignId, campaignMember.id, {
											openInMailEnabled: false,
											dailyOpenInMailLimit: 0,
										}).then(() => {
											onUpdateOpenInMailState({
												isEnabled: false,
												dailyLimit: 0,
											});
										});
									})
								);
							}
						}}
						loading={isOpenInMailUpdating}
						checked={openInMailState.isEnabled}
					/>
				</>
			)}
		</Space>
	);
}

import React, { useRef } from 'react';
import RangeSelectors, { MIN_DAILY_SCHEDULE_HOURS } from './timeRangeSelector';
import { Row, Col, Switch, Button, Alert, Popconfirm } from 'antd';
import { DaySchedule } from '@copilot/data/responses/interface';
import { ScheduleUtility } from '@copilot/common/utils/schedule';
import Text from 'antd/lib/typography';
import { SYSTEM_DEFAULT_SCHEDULE } from '@copilot/common/pages/settings/schedule/constant';

export enum Weekday {
	Sun,
	Mon,
	Tue,
	Wed,
	Thur,
	Fri,
	Sat,
}

interface WeeklyScheduleSelectorProps {
	schedule: DaySchedule[];
	isSync?: boolean;
	onSubmit?: (weeklySchedule: DaySchedule[], isSync: boolean) => void;
	onUpdate?: (weeklySchedule: DaySchedule[]) => void;
	onSyncUpdate?: (state: boolean) => void;
	onDiscard?: () => void;
	hideFooter?: boolean;
	descriptionText?: string;
	alertMessage?: string;
}

const WeeklyScheduleSelector: React.FC<WeeklyScheduleSelectorProps> = (props) => {
	const {
		schedule,
		isSync,
		onSubmit,
		onUpdate,
		onSyncUpdate,
		onDiscard,
		hideFooter,
		descriptionText,
		alertMessage = 'We recommend to run the campaign for at least 6 hours a day.',
	} = props;

	const errorMessageRef = useRef<HTMLDivElement>(null);
	const [sync, setSync] = React.useState<boolean>(false);
	const [weeklySchedule, setWeeklySchedule] = React.useState<DaySchedule[]>();

	React.useEffect(() => {
		onUpdate && weeklySchedule && onUpdate(weeklySchedule);
	}, [weeklySchedule]);

	React.useEffect(() => {
		isSync && setSync(isSync);
	}, [isSync]);

	const weekdayTitles = React.useMemo(
		() =>
			Object.keys(Weekday).filter(
				(day) => typeof Weekday[day as keyof typeof Weekday] === 'number'
			),
		[]
	);

	const onWeekdayEnableToggle = React.useCallback(
		(label: string, status: boolean) => {
			if (!weeklySchedule) return;
			const newSchedule = weeklySchedule;
			newSchedule[Weekday[label as keyof typeof Weekday]] = {
				start: weeklySchedule[Weekday[label as keyof typeof Weekday]].start,
				end: weeklySchedule[Weekday[label as keyof typeof Weekday]].end,
				enable: status,
			};
			setWeeklySchedule([...newSchedule]);
		},
		[weeklySchedule]
	);

	React.useEffect(() => {
		setWeeklySchedule(schedule.map((sched) => ({ ...sched })));
	}, []);

	const toggleSync = (status: boolean) => {
		setSync(status);
		if (status) {
			setWeeklySchedule((stateSchedule) => {
				const { start, end } =
					stateSchedule?.find((sched) => sched.enable) ??
					SYSTEM_DEFAULT_SCHEDULE.weeklySchedule[1];
				return stateSchedule?.map((sched) => ({ ...sched, start, end }));
			});
		}
		onSyncUpdate?.(status);
	};

	const syncSwitch = (checked: boolean) => (
		<Row style={{ marginTop: '5px', marginBottom: '60px' }}>
			<Col span={1} style={{ minWidth: '4em' }} />
			<Col span={1} style={{ minWidth: '4em' }}>
				<Switch checked={checked} onChange={toggleSync} />
			</Col>
			<Col span={16}>Synchronize sending hours of all days</Col>
		</Row>
	);

	const handleSubmit = React.useCallback(() => {
		if (!weeklySchedule || !onSubmit) return;
		onSubmit(weeklySchedule, sync);
	}, [onSubmit, weeklySchedule, sync]);

	const handleTimeChange = React.useCallback(
		(label: string, value: number[]) => {
			const index = Weekday[label as keyof typeof Weekday];
			setWeeklySchedule((scheduleState) => {
				return scheduleState?.map((sched, idx) => {
					if (idx === index)
						return { start: value[0], end: value[1], enable: sched.enable };
					else return { ...sched };
				});
			});
		},
		[weeklySchedule]
	);

	const disabledEveryday = React.useMemo(
		() => weeklySchedule?.findIndex((day) => day.enable) !== -1,
		[weeklySchedule]
	);

	const hasInvalid = React.useMemo(
		() => ScheduleUtility.getScheduleError(weeklySchedule),
		[weeklySchedule]
	);

	React.useEffect(() => {
		if (hasInvalid?.excessiveRunDay) {
			errorMessageRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [hasInvalid?.excessiveRunDay]);

	/**
	 * Validator for our range inputs
	 * @returns Error string or undefined
	 * @param value
	 */
	const validateRange = (value: number[]) => {
		const [start, end] = value;
		if (end - start < MIN_DAILY_SCHEDULE_HOURS * 60) {
			return `Please set to at least ${MIN_DAILY_SCHEDULE_HOURS}-hours`;
		} else {
			return undefined;
		}
	};

	return (
		<>
			{descriptionText && (
				<Row gutter={[0, 24]}>
					<Col>
						<Text>{descriptionText}</Text>
					</Col>
				</Row>
			)}
			<Row style={{ marginBottom: '1.5em' }}>
				<Col>
					<Alert message={alertMessage} type="warning" showIcon />
				</Col>
			</Row>

			{syncSwitch(sync)}
			<RangeSelectors isSynced={sync}>
				{weekdayTitles.map((day) => {
					const currentSchedule = weeklySchedule?.[Weekday[day as keyof typeof Weekday]];
					return (
						currentSchedule && (
							<RangeSelectors.Slider
								key={day}
								label={day}
								isEnabled={currentSchedule.enable}
								onEnableToggle={(state) => onWeekdayEnableToggle(day, state)}
								value={[currentSchedule.start, currentSchedule.end]}
								onChange={(value) => handleTimeChange(day, value)}
								validator={validateRange}
							/>
						)
					);
				})}
			</RangeSelectors>
			<div ref={errorMessageRef}>
				<Row>
					<Col flex="auto" style={{ minWidth: '40em' }}>
						<Alert
							type="error"
							message="Cannot enable more than 5 days a week"
							style={{
								visibility: hasInvalid?.excessiveRunDay ? 'visible' : 'hidden',
							}}
						/>
					</Col>
				</Row>
			</div>
			{!hideFooter && (
				<Row style={{ marginTop: '5px' }} justify="end">
					<Col span={1} style={{ minWidth: '5em' }}>
						<Popconfirm
							title="All days are disabled. Are you sure?"
							onConfirm={handleSubmit}
							disabled={disabledEveryday}
						>
							<Button
								type="primary"
								onClick={disabledEveryday ? handleSubmit : undefined}
								disabled={
									hasInvalid.insufficientRuntime || hasInvalid.excessiveRunDay
								}
							>
								Save
							</Button>
						</Popconfirm>
					</Col>
					<Col span={1}>
						<Button style={{ minWidth: '5em' }} onClick={onDiscard}>
							Discard
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
};

export default WeeklyScheduleSelector;

import { FC, useState } from 'react';
import { Row, Col, Button, Input, Form } from 'antd';
import { NameErrorCodes } from '@copilot/common/utils';

export type NameValidator = (nameToValidate: string) => NameErrorCodes;

export const ErrorMessages: Record<NameErrorCodes, string> = {
	[NameErrorCodes.NoError]: '',
	[NameErrorCodes.NoName]: 'Please enter a tag name',
	[NameErrorCodes.DuplicatedName]:
		'There is already a tag with this name. Please enter a different name.',
	[NameErrorCodes.TooLong]: 'Must be less than 50 characters',
};

interface INameEditorProps {
	description: string;
	continueText: string;
	nameValidator: NameValidator;
	onContinue: (name: string) => void;
	isLoading: boolean;
}

/**
 * [Presentational] Modal Content for the Name Editor Step of Tag Creation
 * @param {string} description description for the name editor modal
 * @param {string} continueText text shown in the button for proceeding
 * @param {NameValidator} nameValidator validates the name that is entered
 * @param {function} onContinue called when continue is clicked
 * @param {boolean} isLoading whether we want to show loading state
 */
const NameEditor: FC<INameEditorProps> = (props) => {
	const { description, continueText, nameValidator, isLoading } = props;
	const [name, setName] = useState<string>();
	const [nameError, setNameError] = useState<NameErrorCodes>();

	const onContinue = () => {
		if (name) {
			props.onContinue(name);
		}
	};

	const validateStatus = () => {
		const hasNameError =
			nameError === undefined ? undefined : nameError !== NameErrorCodes.NoError;
		return hasNameError ? 'error' : hasNameError === undefined ? undefined : 'success';
	};

	return (
		<>
			<Row justify="center" gutter={[16, 8]}>
				<Col>
					<strong>{description}</strong>
				</Col>
			</Row>
			<br />
			<Row justify="center" gutter={[16, 16]}>
				<Col span={16}>
					<Form>
						<Form.Item
							name="tag"
							help={nameError ? ErrorMessages[nameError] : undefined}
							validateStatus={validateStatus()}
							rules={[
								() => ({
									validator(_, value: string) {
										setNameError(nameValidator(value));
									},
								}),
							]}
						>
							<Input onChange={(e) => setName(e.target.value)} />
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<br />
			<Row justify="center" gutter={[16, 16]}>
				<Col>
					<Button
						type="primary"
						block
						onClick={onContinue}
						disabled={nameError !== NameErrorCodes.NoError}
						loading={isLoading}
					>
						{continueText}
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default NameEditor;

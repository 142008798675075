import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Drawer, Button, Form } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import DrawerManager from '@copilot/common/utils/drawerManager';
import DynamicAdditions, {
	DynamicAdditionsValue,
	DA_DEFAULT_KEY,
} from '@copilot/common/components/forms/common/inputs/dynamicAdditions';
import type {
	OrganizationMemberResponse,
	RegisterOrganizationMembersError,
} from '@copilot/data/responses/interface';
import { Rule } from 'rc-field-form/lib/interface';
import { useDispatch } from 'react-redux';
import { AddTeamMembersAction } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/saga';
import { IOrganizationTeamMember } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/models';

interface MemberAdditionDrawerProps extends DrawerProps {
	id: string;
	numAddableMembers: number;
	onSuccess?: (
		registered: OrganizationMemberResponse[],
		errors: RegisterOrganizationMembersError[]
	) => void;
	/**
	 * If true blocks addition of users to an org if a user already exists with email and belongs to another organization
	 */
	blockMultipleOrgs?: boolean;
}

interface MemberAdditionFieldKeys {
	firstname: string;
	lastname: string;
	email: string;
}

const StyledDrawer = styled(Drawer)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.${(props) => props.theme['@ant-prefix']}-drawer-body {
		padding-top: 48px;
	}
	.${(props) => props.theme['@ant-prefix']}-drawer-close {
		position: absolute;
		top: 20px;
		right: 0;
		z-index: 1;
	}
`;

const emailValidateTrigger = ['onChange', 'onBlur'];
const emailFieldRules: Rule[] = [
	{
		type: 'email',
		required: true,
		whitespace: true,
		message: 'Please input an email or remove this field.',
	},
];

const MemberAdditionDrawer: React.FC<MemberAdditionDrawerProps> = (props) => {
	const {
		id,
		numAddableMembers,
		width = '750px',
		onSuccess,
		blockMultipleOrgs,
		...drawerProps
	} = props;
	const [numFormFields, setNumFormFields] = useState<number>(1);
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const onSuccessResult = useCallback(
		(registered: IOrganizationTeamMember[], errors: RegisterOrganizationMembersError[]) => {
			if (onSuccess) onSuccess(registered, errors);
		},
		[]
	);

	const onComplete = DrawerManager.closeDrawer;

	const isAddMemberDisabled = useMemo(
		() => numFormFields >= numAddableMembers,
		[numFormFields, numAddableMembers]
	);

	const handleFinish = useCallback(
		(values: DynamicAdditionsValue<MemberAdditionFieldKeys>) => {
			const members = values[DA_DEFAULT_KEY];
			const membersToAdd = members.map((member) => ({ ...member, blockMultipleOrgs }));
			dispatch(AddTeamMembersAction(id, membersToAdd, onSuccessResult, onComplete));
		},
		[id]
	);

	return (
		<StyledDrawer {...drawerProps} width={width} visible>
			<Form form={form} onFinish={handleFinish}>
				<DynamicAdditions<ArrayElement<Parameters<typeof onSuccessResult>[0]>>
					addButtonText="Add Another Person"
					form={form}
					isAddDisabled={isAddMemberDisabled}
					afterAddChild={() => setNumFormFields((num) => num + 1)}
					afterRemoveChild={() => setNumFormFields((num) => num - 1)}
				>
					<DynamicAdditions.Child
						span={6}
						label="First Name"
						placeholder="First Name"
						propertyName="firstname"
					/>
					<DynamicAdditions.Child
						span={6}
						label="Last Name"
						placeholder="Last Name"
						propertyName="lastname"
					/>
					<DynamicAdditions.Child
						span={12}
						label="Email"
						placeholder="Email"
						propertyName="email"
						validateTrigger={emailValidateTrigger}
						rules={emailFieldRules}
					/>
				</DynamicAdditions>
				<div>
					<p>
						When you send an invitation, a welcome email will be sent to the team
						members you added, asking them to log into the platform under the specified
						organization.
					</p>
					<p>
						Email addresses that are already registered to an account will not receive
						additional invitations.
					</p>
				</div>
				<Form.Item>
					<Button htmlType="submit" type="primary">
						Send Invitation
					</Button>
				</Form.Item>
			</Form>
		</StyledDrawer>
	);
};

export default MemberAdditionDrawer;

import { Button, Col, List, Row, Space, Typography } from 'antd';
import { AttentionRequiredLevelLabels, AttentionRequiredLevels } from './constant';
import AttentionRequiredTagInternal from './tag';
import { ReactNode } from 'react';
import LogRocket from 'logrocket';

type AttentionRequiredItemType = {
	/**
	 * Level of severity
	 */
	level: keyof typeof AttentionRequiredLevels;
	/**
	 * The item that requires attention
	 */
	issue: ReactNode;
	/**
	 * Action's label
	 */
	actionLabel: string;
	/**
	 * OnClick callback
	 */
	onActionClick: () => void;
};

type AttentionRequiredItemListPropsType = {
	/**
	 * Attention required items
	 */
	items: AttentionRequiredItemType[];
};

/**
 * [Persentational] List view of attention required items
 */
function AttentionRequiredItemList(props: AttentionRequiredItemListPropsType): JSX.Element {
	const { items } = props;
	return (
		<List
			dataSource={items}
			renderItem={(item) => (
				<List.Item>
					<Row
						justify="space-between"
						align="middle"
						style={{ width: '100%' }}
						wrap={false}
					>
						<Col flex="auto">
							<Space>
								<AttentionRequiredTagInternal level={item.level}>
									{AttentionRequiredLevelLabels[item.level]}
								</AttentionRequiredTagInternal>
								<Typography>{item.issue}</Typography>
							</Space>
						</Col>
						<Col flex="none">
							<Button
								className="attn-required-actionable-buttons"
								onClick={() => {
									LogRocket.track('Attention Required Button Clicked');
									item.onActionClick();
								}}
							>
								{item.actionLabel}
							</Button>
						</Col>
					</Row>
				</List.Item>
			)}
		/>
	);
}

export default AttentionRequiredItemList;

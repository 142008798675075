import * as React from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import MessageTemplateForm from './form';
import { CheckboxObj } from '@copilot/common/components/forms/common/generics/bubblecheckboxgroup';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { TemplateManager } from '@copilot/data';
import { IMessageTemplate } from '@copilot/common/store/models/redux/messageTemplate';
import { MessageTemplateSelectors } from '@copilot/common/store/selectors/messageTemplate';
import drawerManager from '@copilot/common/utils/drawerManager';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useMemo } from 'react';
import { MessageTemplateActions } from '@copilot/common/store/actions';
import { OrmState } from 'redux-orm';

interface OwnProps extends DrawerProps {
	id: string;
	organizationId: string;
	orgMemberId: string;
	campaignIds: string[];
	showCampaigns: boolean;
}

export type MessageTemplateDrawerProps = OwnProps;

const MessageTemplateDrawer: React.FC<MessageTemplateDrawerProps> = (props) => {
	const {
		id: _id,
		organizationId: _organizationId,
		orgMemberId: _orgMemberId,
		campaignIds,
		showCampaigns,
		...rest
	} = props;
	const campaignsLoaded = useSelector(CampaignSelectors.getCampaigns);
	const initialSelectedIds = useMemo(() => new Set(campaignIds), [campaignIds]);
	const template = useSelector((state: { entities: OrmState<any> }) =>
		MessageTemplateSelectors.getMessageTemplate(state, props.id)
	);
	const storeDispatch = useDispatch();
	const campaignOptions = campaignsLoaded.map((c) => {
		let checked = false;

		if (initialSelectedIds.has(c.id) || !showCampaigns) {
			checked = true;
		}
		return new CheckboxObj(c.id, checked, c.name);
	});

	const onSubmit = (templateName: string, message: string, campaigns: string[] = []) => {
		const temp = {
			organizationId: props.organizationId,
			orgMemberId: props.orgMemberId,
			campaignIds: campaigns,
			name: templateName,
			message,
		} as Partial<IMessageTemplate>;
		if (props.id) {
			temp.id = props.id;
			TemplateManager.editTemplateMessage(temp)
				.then((templateResponse) => {
					storeDispatch(MessageTemplateActions.updateOne(templateResponse));
					notificationManager.showSuccessNotification({
						message: 'Quick response updated',
						description: 'Your quick response has been updated successfully',
					});
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'Changes failed to save',
						description: 'Please try again',
					});
				});
		} else {
			TemplateManager.createTemplateMessage(temp)
				.then((templateResponse) => {
					storeDispatch(MessageTemplateActions.updateOne(templateResponse));
					notificationManager.showSuccessNotification({
						message: 'Quick response created',
						description: 'Your quick response has been created',
					});
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'Failed to add quick response',
						description: 'Please try again',
					});
				});
		}

		drawerManager.closeDrawer();
	};

	return (
		<Drawer
			width="720px"
			open
			rootStyle={{ display: 'flex', height: '100%', flexDirection: 'column' }}
			{...rest}
		>
			<MessageTemplateForm
				showCampaigns={showCampaigns}
				initialCampaigns={campaignOptions}
				onSubmit={onSubmit}
				template={template}
			/>
		</Drawer>
	);
};

export default MessageTemplateDrawer;
